import type { datadogRum, RumInitConfiguration } from "@datadog/browser-rum-slim"

import { getCookie } from "@/modules/client/cookies/getCookies"

export type DatadogRum = typeof datadogRum | undefined
let datadogRumInstance: DatadogRum

// Have trouble seeing DD RUM events ?
// See https://docs.datadoghq.com/fr/real_user_monitoring/browser/troubleshooting/

// Searching for RUM DD interface ?
// See https://app.datadoghq.com/rum/application/bfa173ce-8868-4f49-ae9d-5e2b3a94e216/overview/browser
// See https://app.datadoghq.com/dash/integration/rum_performance

const getDatadogRumConfig = (version: string): RumInitConfiguration => ({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID || "",
  clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || "",
  env: process.env.NEXT_PUBLIC_ENV,
  service: "next-jobteaser",
  /*
   * "sampleRate" is the % of users who are in the measures
   * Be careful updating this value, it could increase or decrease the monthly price.
   */
  sessionSampleRate:
    // See README for how to use cookie "force_rum"
    getCookie("force_rum") === "1" ? 100 : parseInt(process.env.NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE || "0", 10),

  site: "datadoghq.com",

  // The trackUserInteractions initialization parameter enables the collection of user clicks in your application
  trackUserInteractions: true,

  version,
})

export const datadogRumInit = async (version: string): Promise<DatadogRum | undefined> => {
  // Note: window.JT_DD_INITIALIZED is only needed for local hot reload that shows an error in console
  // when `datadogRum.init()` is called multiple time.
  if (process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED === "true" && !window.JT_DD_INITIALIZED) {
    window.JT_DD_INITIALIZED = true
    const { datadogRum: rum } = await import("@datadog/browser-rum-slim")
    rum.init(getDatadogRumConfig(version))
    datadogRumInstance = rum
    return datadogRumInstance
  }

  return undefined
}
