import type { AlgoliaJobAdsRequest } from "@/modules/jobAds/client/utils/computeAlgoliaJobAdsRequest"
import type { MultipleQueriesQuery, SearchOptions } from "@algolia/client-search"

import { usePathname } from "next/navigation"
import { type FunctionComponent, useEffect, useState } from "react"

import { Divider } from "@jobteaser/spark/components/Divider"

import { isJobAdsSearchUrl } from "@/modules/jobAds/client/utils/isJobAdsSearchUrl"
import { useNotification } from "@/modules/notification/hooks"

import styles from "./DebugAlgoliaRequests.module.css"

export const DebugAlgoliaRequests: FunctionComponent = () => {
  const [algoliaJobAdsRequest, setAlgoliaJobAdsRequest] = useState<AlgoliaJobAdsRequest | null>(null)
  const showNotification = useNotification()
  const pathname = usePathname()
  const isJobAdSearch = isJobAdsSearchUrl(pathname)

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (window.__APP_DATA__?.algoliaJobAdsRequest) {
      // eslint-disable-next-line no-underscore-dangle
      setAlgoliaJobAdsRequest(window.__APP_DATA__.algoliaJobAdsRequest)
    }
  }, [])

  const retrieveDisplayableAlgoliaParams = (query: MultipleQueriesQuery | undefined): SearchOptions => {
    const queryParams = query?.params

    if (!queryParams) return {}

    return {
      filters: query.params.filters,
      optionalFilters: query.params.optionalFilters,
    }
  }

  const handleSponsoredButtonClick = (): void => {
    if (!algoliaJobAdsRequest?.sponsoredQuery) {
      return
    }

    const sponsoredAlgoliaRequest = algoliaJobAdsRequest.sponsoredQuery
    const sponsoredRequestParams = retrieveDisplayableAlgoliaParams(sponsoredAlgoliaRequest)

    navigator.clipboard.writeText(JSON.stringify(sponsoredRequestParams, undefined, 2))
    showNotification({
      title: "Sponsored params copied!",
      variant: "success",
    })
  }

  const handleMainButtonClick = (): void => {
    if (!algoliaJobAdsRequest?.mainQuery) {
      return
    }

    const mainAlgoliaRequest = algoliaJobAdsRequest.mainQuery
    const mainRequestParams = retrieveDisplayableAlgoliaParams(mainAlgoliaRequest)

    navigator.clipboard.writeText(JSON.stringify(mainRequestParams, undefined, 2))
    showNotification({
      title: "Main params copied!",
      variant: "success",
    })
  }

  if (!algoliaJobAdsRequest) {
    return null
  }

  return (
    <>
      <Divider onBackground="dark" />
      <div className={styles.main}>
        <h4>Debug Algolia Requests</h4>
        <div>
          {isJobAdSearch && (
            <>
              <button type="button" onClick={handleSponsoredButtonClick}>
                Sponsored request
              </button>
              <span>-</span>
            </>
          )}
          <button type="button" onClick={handleMainButtonClick}>
            Main request
          </button>
        </div>
      </div>
    </>
  )
}
