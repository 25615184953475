"use client"

import type { FunctionComponent } from "react"

import ReactDOM from "react-dom"

import { getAssetsUrl } from "@/modules/staticAssets/assetsManager/assetsManager"

const EXTERNAL_ORIGINS = [
  "https://cdn.rudderlabs.com",
  "https://api.rudderlabs.com",
  "https://jobteaser-dataplane.rudderstack.com",
  "https://static.axept.io",
  "https://client.axept.io",
  "https://www.googletagmanager.com",
  "https://sdk.privacy-center.org",
  "https://unleash.jobteaser.com",
]

export const HeadPreloadResources: FunctionComponent = () => {
  const assetsUrl = getAssetsUrl()

  if (assetsUrl) {
    const cdnUrl = new URL(assetsUrl)
    const externalOrigins = [...EXTERNAL_ORIGINS, `${cdnUrl.protocol}//${cdnUrl.hostname}`]

    externalOrigins.forEach(origin => {
      ReactDOM.preconnect(origin, { crossOrigin: "" })
      ReactDOM.prefetchDNS(origin)
    })
  }

  // This image is used in the GDPR modal so we preload it to speedup the rendering.
  // It's using the production URL so we don't use the related env asset url.
  ReactDOM.preload("https://static-assets.jobteasercdn.com/assets/logos/picto-jt-logo.svg", { as: "image" })

  return null
}
