"use client"

import type { EnabledFlag, UnleashContext } from "@/modules/unleash/types"
import type { CurrentUser } from "@/modules/user/api/types"
import type { RudderstackMod } from "@jobteaser/js-tracking"
import type { FunctionComponent, PropsWithChildren } from "react"

import { useEffect, useState } from "react"

import { getCookie } from "@/modules/client/cookies/getCookies"
import { setCookie } from "@/modules/client/cookies/setCookie"
import { useLocale } from "@/modules/locales/useLocale"
import { initTracking } from "@/modules/tracking/initTracking"
import { TrackingLibContext } from "@/modules/tracking/useTracking"
import { getExperimentInfosFromCookie } from "@/modules/unleash/getExperimentInfosFromCookie"

type TrackingAppProviderProps = PropsWithChildren<{
  activeFlags: EnabledFlag[]
  unleashContext: UnleashContext
  userId: CurrentUser["attributes"]["uuid"]
}>

const EXPERIMENT_INFO_COOKIE_NAME = "jt_unleash_experiment_info"

export const TrackingInitProvider: FunctionComponent<TrackingAppProviderProps> = ({
  activeFlags,
  children,
  unleashContext,
  userId,
}) => {
  const locale = useLocale()
  const [tracking, setTracking] = useState<RudderstackMod | null>(null)

  useEffect(() => {
    ;(typeof window.requestIdleCallback !== "undefined" ? window.requestIdleCallback : setTimeout)(() => {
      initTracking(locale, unleashContext, setTracking)
    })
  }, [locale, unleashContext])

  useEffect(() => {
    // Waiting for tracking client (RudderStack) to be available
    if (!tracking) {
      return
    }

    const syncUserInfo = (): void => {
      const experimentInfos = activeFlags.map(({ name, variant }) => `${name}_${variant || "enabled"}`)

      // Note that the cookie is NOT shared with "ui-jobteaser" (spa pages)
      const savedExperimentInfos = getExperimentInfosFromCookie(getCookie(EXPERIMENT_INFO_COOKIE_NAME))

      // We should sync user info to Rudderstack if the unleash flags have changed
      // or if the user id registered in Rudderstack is different from the current user id
      const shouldBeSynced =
        JSON.stringify(experimentInfos) !== JSON.stringify(savedExperimentInfos) || tracking.getUserId() !== userId

      // Updating the cookie anyway so the expiration date is updated too
      setCookie({ name: EXPERIMENT_INFO_COOKIE_NAME, path: "/", value: window.btoa(experimentInfos.toString()) })

      if (shouldBeSynced) {
        tracking.identify({ unleash_experiment_info: experimentInfos }, userId || null)
      }
    }

    syncUserInfo()

    tracking.start()
  }, [activeFlags, tracking, userId])

  return <TrackingLibContext.Provider value={tracking}>{children}</TrackingLibContext.Provider>
}
