import type { Metadata, TrackJSMod } from "./types"

import { createContext, useContext } from "react"

type TrackJsContextType = { trackJS: TrackJSMod | null; getMetadata?: () => Metadata }

export const TrackJsContext = createContext<TrackJsContextType>({ trackJS: null })

export const useTrackJs = (): TrackJSMod | null => {
  const { trackJS } = useContext(TrackJsContext)

  return trackJS
}
