import type { CookieType } from "@/modules/client/cookies/types"

type CookieDeclarationType = Omit<CookieType, "name" | "value"> & {
  [key: string]: unknown
}
export function setCookie({ name, value, expires, path = "/", theDomain, secure }: CookieType): void {
  // method could only be used on client side.
  if (typeof window === "undefined") {
    return
  }

  if (!name) {
    return
  }

  const today = new Date()
  // cookie expires in a year
  const defaultExpire = new Date(today.setFullYear(today.getFullYear() + 1))

  const escapedValue = encodeURI(value)
  const expireParam = expires || defaultExpire.toUTCString()

  const cookieValues: CookieDeclarationType = {
    [name]: escapedValue,
    ...(expireParam && { expires: expireParam }),
    ...(path && { path }),
    ...(theDomain && { domain: theDomain }),
  }

  let cookieString = Object.keys(cookieValues)
    .map(item => {
      return `${item}=${cookieValues[item]}`
    })
    .join(";")

  // Add secure params
  cookieString = `${cookieString};${secure ? "secure" : ""}`

  document.cookie = cookieString
}
