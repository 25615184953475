import type { TrackJsConfig, TrackJsOptionalConfig } from "./types"

import { BUILD_VERSION } from "@/modules/env/constants"
import { getCurrentEnv } from "@/modules/env/utils"

export const getTrackJSConfiguration = (optionalOptions: TrackJsOptionalConfig = {}): TrackJsConfig => {
  const currentEnv = getCurrentEnv()
  const trackJSApplication: TrackJsConfig["application"] = `jt-nextjs-${
    currentEnv === "feature" ? "staging" : currentEnv
  }`
  const requiredOptions: TrackJsConfig = {
    application: trackJSApplication,
    token: process.env.NEXT_PUBLIC_TRACKJS_TOKEN || "",
    version: BUILD_VERSION,
  }

  const staticOptions: TrackJsOptionalConfig = {
    console: {
      display: false,
      enabled: true,
      error: true,
      watch: ["log", "debug", "info", "warn", "error"],
    },
  }

  return { ...optionalOptions, ...staticOptions, ...requiredOptions }
}
