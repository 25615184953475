// From RFC4648 http://www.ietf.org/rfc/rfc4648.txt
// ^                          # Start of input
// ([0-9a-zA-Z+/]{4})*        # Groups of 4 valid characters decode
//                            # to 24 bits of data for each group
// (                          # Either ending with:
//     ([0-9a-zA-Z+/]{2}==)   # two valid characters followed by ==
//     |                      # , or
//     ([0-9a-zA-Z+/]{3}=)    # three valid characters followed by =
// )?                         # , or nothing
// $                          # End of input
//
const isBase64 = (value: string): boolean =>
  /^(?:[0-9a-zA-Z+/]{4})*(?:[0-9a-zA-Z+/]{2}==|[0-9a-zA-Z+/]{3}=)?$/.test(value)

export const getExperimentInfosFromCookie = (cookieValue: string): string[] => {
  // method could only be used on client side.
  if (typeof window === "undefined") {
    return []
  }

  if (isBase64(cookieValue)) {
    return window.atob(cookieValue).split(",")
  }

  return []
}
