import type { FunctionComponent } from "react"

import { VISITOR_ID_COOKIE_NAME } from "@/modules/authentication/constants"
import { getCookie } from "@/modules/client/cookies/getCookies"
import grafanaIcon from "@/modules/debug/images/grafana-icon.svg"

import styles from "./DebugInfoDisplay.module.css"

type DebugInfoDisplayProps = {
  requestId: string
  grafanaDebugUrl: string
}

export const DebugInfoDisplay: FunctionComponent<DebugInfoDisplayProps> = props => {
  const { requestId, grafanaDebugUrl } = props

  const visitorId = getCookie(VISITOR_ID_COOKIE_NAME)

  const debugInfoUrl = new URL(grafanaDebugUrl)

  if (requestId) {
    debugInfoUrl.searchParams.append("var-request_id", requestId)
  }

  return (
    <div className={styles.main}>
      {!!requestId && (
        <div className={styles.debugLine}>
          <span>Request ID:</span>
          <div>
            <code>{requestId}</code>
            <a href={debugInfoUrl.toString()} target="_blank" title="Debug on Grafana">
              <img src={grafanaIcon} alt="Grafana icon" />
            </a>
          </div>
        </div>
      )}
      {!!visitorId && (
        <div className={styles.debugLine}>
          <span>Visitor ID:</span>
          <code>{visitorId}</code>
        </div>
      )}
    </div>
  )
}
