"use client"

import type { FunctionComponent, PropsWithChildren } from "react"

import { useEffect, useState } from "react"

import { BUILD_VERSION } from "@/modules/env/constants"
import { useHref } from "@/modules/routing/hooks/useHref"
import { getClientPageType } from "@/modules/routing/routingManager/clientRoutingManager"

import { type DatadogRum, datadogRumInit } from "./datadog"

export const DatadogProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [datadogRum, setDatadogRum] = useState<DatadogRum | undefined>()
  const href = useHref()

  useEffect(() => {
    datadogRumInit(BUILD_VERSION).then(setDatadogRum).catch(console.error)
  }, [])

  useEffect(() => {
    if (datadogRum) {
      datadogRum.setGlobalContextProperty("pageType", getClientPageType() || "nofeature")
    }
  }, [datadogRum, href])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
