"use client"

import type { NotificationParams } from "@/modules/notification/types"
import type { ComponentType, FunctionComponent, PropsWithChildren, ReactElement } from "react"

import dynamic from "next/dynamic"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { logger } from "@/modules/monitoring/logger"
import { NotificationContext } from "@/modules/notification/Contexts/NotificationContext"

const DynamicSonnerToaster = dynamic(
  () => import("@/modules/notification/components/SonnerToaster").then(mod => mod.SonnerToaster),
  { ssr: false }
)

type SonnerModule = {
  toast: {
    custom: (jsx: (id: number | string) => ReactElement) => string | number
    dismiss: (id?: number | string) => string | number
  }
  Toaster: ComponentType
}

export const NotificationProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const sonnerModule = useRef<SonnerModule | null>(null)
  const [isSonnerToasterReady, setIsSonnerToasterReady] = useState(false)
  const [pendingNotifications, setPendingNotifications] = useState<NotificationParams[]>([])

  const initialize = useCallback(async () => {
    try {
      const sonner = await import("sonner")

      sonnerModule.current = {
        Toaster: sonner.Toaster,
        toast: sonner.toast,
      }
    } catch (error) {
      logger.error("Failed to initialize notification module", error)
    }
  }, [])

  const displayToast = useCallback(async (params: NotificationParams) => {
    if (!sonnerModule.current) {
      return
    }

    const { title, content, variant = "info", closeLabel } = params
    const { toast } = sonnerModule.current
    const { Toast } = await import("@jobteaser/spark/components/Toast")

    toast.custom(currentToast =>
      closeLabel !== undefined ? (
        <Toast
          title={title}
          variant={variant}
          hasCloseAction
          closeButtonLabel={closeLabel}
          onClose={() => toast.dismiss(currentToast)}
        >
          {content}
        </Toast>
      ) : (
        <Toast title={title} variant={variant}>
          {content}
        </Toast>
      )
    )
  }, [])

  /**
   * Triggers a notification
   * @param title - Title of the notification
   * @param content - Content of the notification
   * @param variant - Variant of the notification (info, success, warning, error)
   * @param closeLabel - Label of the close button, no close button if undefined
   */
  const showNotification = useCallback(
    async (params: NotificationParams) => {
      try {
        if (!sonnerModule.current) {
          await initialize()
        }

        if (isSonnerToasterReady) {
          displayToast(params)
        } else {
          setPendingNotifications(previousParams => [...previousParams, params])
        }
      } catch (error) {
        logger.error("Failed to show notification:", error)
      }
    },
    [displayToast, initialize, isSonnerToasterReady]
  )

  useEffect(() => {
    if (pendingNotifications.length > 0 && isSonnerToasterReady) {
      pendingNotifications.forEach(displayToast)
      setPendingNotifications([])
    }
  }, [displayToast, isSonnerToasterReady, pendingNotifications])

  return (
    <NotificationContext.Provider value={useMemo(() => ({ showNotification }), [showNotification])}>
      {children}
      {sonnerModule.current && <DynamicSonnerToaster onReady={() => setIsSonnerToasterReady(true)} />}
    </NotificationContext.Provider>
  )
}
