"use client"

import type { ShowNotification } from "@/modules/notification/types"

import { useContext } from "react"

import { NotificationContext } from "@/modules/notification/Contexts/NotificationContext"

export const useNotification = (): ShowNotification => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error(`useNotification must be used within a NotificationProvider`)
  }
  return context.showNotification
}
